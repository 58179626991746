import React, { useState } from "react";
import rules_link from "../img/regle.png";

const Regles = () => {
  const [animate, setAnimate] = useState(false);

  const handleClick = () => {
    setAnimate(true);
    setTimeout(() => {
      window.open("https://www.randa.org/en/rog/the-rules-of-golf", "_blank");
      setAnimate(false);
    }, 500); // Temps de l'animation avant l'ouverture du lien
  };

  return (
    <>
      <div className={`main-component ${animate ? "animate" : ""}`}>
        {/* Conteneur cliquable */}
        <div className="container__rules align-center" onClick={handleClick} style={{ cursor: "pointer" }}>
          {/* Image */}
          <img src={rules_link} alt="Regle du golf" />

          {/* Texte et bouton */}
          <div className="rules">
            <p className="rules__title">Règles de Golf</p>
            <p className="rules__p">R&A Rules Limited</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Regles;
