import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import Message from "./message";
import TypingIndicator from "./TypingIndicator";
import { FaMicrophone } from "react-icons/fa6";
import { MdPhotoCamera } from "react-icons/md";

function Chatbot() {
  // État pour stocker tous les messages affichés
  const [messages, setMessages] = useState([]);
  // État pour stocker l'historique conversationnel (format GPT)
  const [conversationHistory, setConversationHistory] = useState([]);
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isListening, setIsListening] = useState(false);
  const [recognition, setRecognition] = useState(null);
  const [explanation, setExplanation] = useState(false);
  const [photoData, setPhotoData] = useState(null);
  const [imageProcessing, setImageProcessing] = useState(false);
  const [imageError, setImageError] = useState(null);

  // Référence vers l'élément qui contient la liste des messages
  const chatWindowRef = useRef(null);

  // ➜ Scroll automatique à chaque changement de `messages` - VERSION AMÉLIORÉE
  useLayoutEffect(() => {
    if (chatWindowRef.current) {
      // Scroll immédiat
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
      
      // Puis un autre scroll après un court délai pour s'assurer que tout le contenu est rendu
      setTimeout(() => {
        if (chatWindowRef.current) {
          chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
        }
      }, 100);
    }
  }, [messages]);

  // ➜ Observer pour s'assurer que le scroll fonctionne même si le contenu change de taille
  useEffect(() => {
    if (!chatWindowRef.current) return;
    
    const observer = new MutationObserver(() => {
      if (chatWindowRef.current) {
        chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
      }
    });
    
    observer.observe(chatWindowRef.current, {
      childList: true,
      subtree: true,
      characterData: true
    });
    
    return () => observer.disconnect();
  }, []);

  // ➜ Initialisation de la reconnaissance vocale, si compatible
  useEffect(() => {
    if ("webkitSpeechRecognition" in window) {
      const SpeechRecognition = window.webkitSpeechRecognition;
      const recognitionInstance = new SpeechRecognition();
      recognitionInstance.continuous = false;
      recognitionInstance.interimResults = false;
      recognitionInstance.lang = "fr-FR";

      recognitionInstance.onresult = (event) => {
        const transcript = event.results[0][0].transcript;
        setInput((prev) => prev + " " + transcript);
        setIsListening(false);
      };

      recognitionInstance.onerror = (event) => {
        console.error("Erreur reconnaissance vocale:", event.error);
        setIsListening(false);
      };

      setRecognition(recognitionInstance);
    }
  }, []);

  // ➜ Message de bienvenue
  useEffect(() => {
    const welcomeMessage = {
      sender: "bot",
      text: "Bonjour ! Je suis votre assistant golf. Posez-moi une question !",
      timestamp: new Date().toISOString(),
    };
    
    setMessages([welcomeMessage]);
    
    // Historique de conversation pour GPT
    setConversationHistory([
      {
        role: "assistant",
        content: welcomeMessage.text
      }
    ]);
  }, []);

  const API_BASE_URL = process.env.NODE_ENV === "development" 
  ? "http://localhost:5000" 
  : "https://naoandco.com";

  // ➜ Fonction pour appeler l'API et obtenir la réponse - MODIFIÉE AVEC FLAG POUR LES IMAGES
const getAIResponse = async (message, explication) => {
  try {
    // Préparer le message utilisateur pour l'API
    const userMessageForAPI = {
      role: "user",
      content: message || "Analyse cette image" // Ajouter un message par défaut
    };
    
    // Historique complet à envoyer
    const historyToSend = [...conversationHistory, userMessageForAPI];
    
    // Construction du body pour la requête
    const requestBody = {
      messages: historyToSend,
      explanation: explication
    };
    
    // Ajouter l'image si présente et le flag d'image
    if (photoData) {
      requestBody.imageData = photoData;
      requestBody.isImageRequest = true; // Flag pour indiquer que c'est une requête avec image
      console.log("Envoi d'une image de taille:", Math.round(photoData.length/1024), "KB");
    }
    
    // Utiliser toujours le même endpoint, le flag isImageRequest indiquera le type de requête
    const endpoint = `${API_BASE_URL}/api/chat`;
    
    console.log("Envoi de la requête à:", endpoint);
    
    // Appel API avec timeout plus long pour les images
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 60000); // 60 secondes

    const response = await fetch(endpoint, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(requestBody),
      signal: controller.signal
    });
    
    clearTimeout(timeoutId);
    
    console.log("Statut de la réponse:", response.status);
    
    if (!response.ok) {
      const errorText = await response.text();
      console.error("Erreur API (status:", response.status, "):", errorText);
      
      // Essayer de parser en JSON pour plus de détails
      try {
        const errorJson = JSON.parse(errorText);
        console.error("Détails de l'erreur:", errorJson);
      } catch (e) {
        // Ce n'est pas du JSON, continuer
      }
      
      throw new Error(`Erreur réseau: ${response.status}`);
    }

    // Récupérer la réponse
    const data = await response.json();
    const aiResponseText = typeof data.response === "string"
      ? data.response
      : "Réponse invalide.";
    
    // Mettre à jour l'historique
    setConversationHistory([
      ...historyToSend,
      {
        role: "assistant",
        content: aiResponseText
      }
    ]);
    
    return aiResponseText;
  } catch (error) {
    console.error("Erreur API détaillée:", error.name, error.message);
    
    if (error.name === "AbortError") {
      return "La requête a pris trop de temps. Essayez avec une image plus petite.";
    }
    
    return "Désolé, une erreur technique est survenue. Veuillez réessayer ou contacter le support.";
  }
};

  // ➜ Envoyer un message (utilisateur)
  const handleSendMessage = async () => {
    if ((!input.trim() && !photoData) || isLoading || imageProcessing) return;
    
    // Réinitialiser toute erreur d'image précédente
    setImageError(null);
    setIsLoading(true);

    // 1) Ajout du message utilisateur
    const userMessage = {
      sender: "user",
      text: input.trim(),
      ...(photoData && { image: `data:image/jpeg;base64,${photoData}` }),
      timestamp: new Date().toISOString(),
    };
    setMessages((prev) => [...prev, userMessage]);

    // 2) Ajout d'un message provisoire "isTyping"
    setMessages((prev) => [...prev, { sender: "bot", isTyping: true }]);

    try {
      // 3) Obtenir la réponse de l'IA
      const aiResponse = await getAIResponse(input.trim(), explanation);

      // 4) Remplacer le message `isTyping` par la réponse finale
      setMessages((prev) =>
        prev.map((msg) =>
          msg.isTyping
            ? { sender: "bot", text: aiResponse, timestamp: new Date().toISOString() }
            : msg
        )
      );
      
      // 5) Forcer un scroll supplémentaire après la mise à jour du state
      setTimeout(() => {
        if (chatWindowRef.current) {
          chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
        }
      }, 100);
      
    } catch (error) {
      console.error("Erreur lors de l'envoi du message:", error);
      
      // Si erreur, remplacer le `isTyping` par un message d'erreur
      setMessages((prev) =>
        prev.map((msg) =>
          msg.isTyping
            ? {
                sender: "bot",
                text: "❌ Erreur serveur.",
                timestamp: new Date().toISOString(),
              }
            : msg
        )
      );
    } finally {
      // 6) Remettre à zéro l'input et l'image
      setIsLoading(false);
      setInput("");
      setPhotoData(null);
    }
  };

  // ➜ Envoyer le message quand on appuie sur "Enter"
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  // ➜ Activation/désactivation du micro
  const toggleVoiceInput = () => {
    if (!recognition) return;
    if (isListening) {
      recognition.stop();
    } else {
      recognition.start();
    }
    setIsListening(!isListening);
  };

  // ➜ Caméra / Choix d'image
  const openCamera = () => {
    if (photoData) {
      // Si une photo est déjà sélectionnée, on l'annule
      setPhotoData(null);
    } else {
      // Ouvrir la sélection de fichier
      document.getElementById("hiddenCameraInput").click();
    }
  };

  // Fonction améliorée de traitement d'image
  const handleFileChange = (event) => {
    const file = event.target.files && event.target.files[0];
    if (!file) return;
  
    // Réinitialiser les erreurs
    setImageError(null);
    setImageProcessing(true);
  
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        try {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
  
          // Réduire la taille de l'image
          const MAX_WIDTH = 400;  // Réduit à 400
          const MAX_HEIGHT = 400; // Réduit à 400
          let width = img.width;
          let height = img.height;
  
          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }
  
          // Arrondir les dimensions pour éviter des problèmes de rendu
          width = Math.floor(width);
          height = Math.floor(height);
          
          canvas.width = width;
          canvas.height = height;
  
          // Dessiner l'image redimensionnée
          ctx.drawImage(img, 0, 0, width, height);
  
          // Compression plus agressive (qualité 0.4 au lieu de 0.6)
          let quality = 0.4;
          let compressedBase64 = canvas.toDataURL("image/jpeg", quality).split(",")[1];
          let iterations = 0;
          const MAX_ITERATIONS = 3;
          
          // Si l'image est encore trop grande, réduire davantage la qualité
          while (compressedBase64.length > 500000 && iterations < MAX_ITERATIONS) {
            quality -= 0.1;
            if (quality < 0.1) quality = 0.1;
            compressedBase64 = canvas.toDataURL("image/jpeg", quality).split(",")[1];
            iterations++;
          }
  
          console.log(
            "📷 Image compressée, taille base64 :",
            compressedBase64.length,
            "caractères (",
            Math.round(compressedBase64.length / 1024),
            "KB), qualité:",
            quality
          );
          
          if (compressedBase64.length > 1000000) {
            throw new Error("L'image est trop volumineuse, même après compression");
          }
          
          setPhotoData(compressedBase64);
          setImageProcessing(false);
        } catch (error) {
          console.error("Erreur de traitement d'image:", error);
          setImageError("Erreur: " + error.message);
          setImageProcessing(false);
        }
      };
      
      img.onerror = (err) => {
        console.error("Erreur de chargement d'image:", err);
        setImageError("Le format d'image n'est pas pris en charge");
        setImageProcessing(false);
      };
      
      img.src = e.target.result;
    };
  
    reader.onerror = () => {
      console.error("Erreur de lecture du fichier");
      setImageError("Impossible de lire l'image");
      setImageProcessing(false);
    };
  
    reader.readAsDataURL(file);
    
    // Réinitialiser l'input pour permettre la sélection du même fichier
    event.target.value = '';
  };

  // ➜ Effacer la conversation
  const clearConversation = () => {
    const welcomeMessage = messages[0]; // On garde le tout premier message
    setMessages([welcomeMessage]);
    setConversationHistory([
      {
        role: "assistant",
        content: welcomeMessage.text
      }
    ]);
    
    // Réinitialiser les erreurs et l'image
    setImageError(null);
    setPhotoData(null);
  };

  return (
    <div className="chatbot-container">
      <div className="chat-header">
        {messages.length > 1 && (
          <button
            className="clear-button"
            onClick={clearConversation}
            title="Effacer la conversation"
          >
            +
          </button>
        )}
      </div>
      
      {/* Fenêtre de chat */}
      <div className="chat-window" ref={chatWindowRef}>
        {messages.map((msg, index) =>
          msg.isTyping ? (
            <TypingIndicator key={index} />
          ) : (
            <Message key={index} msg={msg} />
          )
        )}
      </div>

      {/* Zone d'input */}
      <div className="input-container">
        {photoData && <div className="image-ready-bar"></div>}
        {imageError && <div className="image-error-bar">{imageError}</div>}

        <button
          className={`explication-button ${explanation ? "active" : ""}`}
          onClick={() => setExplanation(!explanation)}
        >
          {explanation ? "Explication activée 📖" : "Besoin d'explication ?"}
        </button>

        <button
          className={`mic-button ${isListening ? "active" : ""}`}
          onClick={toggleVoiceInput}
          disabled={!recognition}
          aria-label="Activer le microphone"
        >
          <FaMicrophone
            style={{
              color: "rgba(255, 255, 255, 0.9)",
              fontSize: "30px",
              width: "15px",
              height: "37px"
            }}
          />
        </button>

        <button
          className={`camera-button ${photoData ? "has-image" : ""} ${imageProcessing ? "processing" : ""}`}
          onClick={openCamera}
          disabled={imageProcessing}
          title={photoData ? "Supprimer l'image" : "Prendre une photo"}
        >
          {imageProcessing ? "⏳" : (photoData ? "❌" : (
            <MdPhotoCamera
              style={{ fontSize: "39px", width: "18px", height: "29px" }}
            />
          ))}
        </button>

        <input
          id="hiddenCameraInput"
          type="file"
          accept="image/*"
          capture="environment"
          style={{ display: "none" }}
          onChange={handleFileChange}
        />

        <textarea
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder={
            photoData
              ? "📷 Image ajoutée ! Écrivez un message..."
              : "Écrivez ou parlez..."
          }
          className={isListening ? "recording" : ""}
          disabled={isLoading}
        />

        <button
          className="send-button"
          onClick={handleSendMessage}
          disabled={isLoading || (!input.trim() && !photoData) || imageProcessing}
        >
          {isLoading ? <div className="spinner"></div> : "➤"}
        </button>
      </div>

      {/* Exemple de styles */}
      <style jsx>{`
        .chat-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 5px 9px;
          background: #373737;
          color: white;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
        }
        
        .chat-header h3 {
          margin: 0;
          font-size: 16px;
        }
        
        .clear-button {
          background: rgb(255 201 201 / 20%);
          border: none;
          color: white;
          padding: 4px 8px;
          border-radius: 4px;
          font-size: 12px;
          cursor: pointer;
          transition: background 0.3s;
        }
        
        .clear-button:hover {
          background: rgba(255, 255, 255, 0.3);
        }
        
        .image-ready-bar {
          position: absolute;
          top: -10px;
          left: 0;
          right: 0;
          height: 4px;
          background: #4CAF50;
          z-index: 10;
        }
        
        .image-error-bar {
          position: absolute;
          top: -25px;
          left: 0;
          right: 0;
          padding: 2px 10px;
          font-size: 12px;
          background: #ff5252;
          color: white;
          text-align: center;
          z-index: 10;
        }
        
        .camera-button.processing {
          background: #ffeb3b;
          color: #333;
          animation: pulse 1.5s infinite;
        }
        
        @keyframes pulse {
          0% {
            opacity: 1;
          }
          50% {
            opacity: 0.5;
          }
          100% {
            opacity: 1;
          }
        }
      `}</style>
    </div>
  );
}

export default Chatbot;