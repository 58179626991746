import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import logo from "../img/headertest.png";
import { IoTimerOutline } from "react-icons/io5";
import { GoBook } from "react-icons/go";
import { BsChatLeftDotsFill } from "react-icons/bs";
import { FaUserCircle } from "react-icons/fa";
import footerImg from "../img/banniereNAO&CO.jpg";

export default function Header({
  title,
  timer1,
  timer2,
  setTimer1,
  setTimer2,
  onMenuClick,
}) {
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [expandedTimer, setExpandedTimer] = useState(null);
  const [closingTimer, setClosingTimer] = useState(null);
  const [justReset, setJustReset] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Effet pour vérifier combien de minuteurs sont visibles
  useEffect(() => {
    // Fonction pour vérifier combien de minuteurs sont visibles
    const checkVisibleTimers = () => {
      const timer1Visible = timer1.isRunning || timer1.timeLeft < 180;
      const timer2Visible = timer2.isRunning || timer2.timeLeft < 14400;
      
      // Récupérer le conteneur des minuteurs
      const timerContainer = document.querySelector('.mobile-timer-container');
      
      if (timerContainer) {
        // Si un seul timer est visible, ajouter la classe 'single-timer'
        if ((timer1Visible && !timer2Visible) || (!timer1Visible && timer2Visible)) {
          timerContainer.classList.add('single-timer');
        } else {
          timerContainer.classList.remove('single-timer');
        }
      }
    };
    
    // Exécuter au chargement du composant et lorsque l'état des minuteurs change
    checkVisibleTimers();
  }, [timer1, timer2]); // Dépendances: les états des deux minuteurs

  function formatTime(totalSeconds, showHours = false) {
    if (showHours) {
      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = totalSeconds % 60;
      return [hours, minutes, seconds]
        .map((num) => String(num).padStart(2, "0"))
        .join(":");
    } else {
      const minutes = Math.floor(totalSeconds / 60);
      const seconds = totalSeconds % 60;
      return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
    }
  }

  const checkBackgroundColor = () => {
    const popinElement = document.querySelector(".mobile-timer.expanded");
    if (!popinElement) return;
  
    // Obtient la couleur du fond sous la popin
    const bgColor = window.getComputedStyle(popinElement.parentElement).backgroundColor;
  
    // Convertit la couleur RGB en luminosité
    const rgb = bgColor.match(/\d+/g);
    if (rgb) {
      const brightness = (0.299 * rgb[0] + 0.587 * rgb[1] + 0.114 * rgb[2]); // Calcul de luminosité
      setIsDarkMode(brightness > 200); // ✅ Si lumineux, active le dark mode
    }
  };
  
  const toggleTimer = (timer) => {
    if (isMobile) {
      setExpandedTimer(expandedTimer === timer ? null : timer);
      setTimeout(checkBackgroundColor, 50); // ✅ Vérifie la couleur après ouverture
    }
  };
  
  /** ✅ Réinitialise le timer **sans fermer la popin** */
  const resetTimer = (timer) => {
    if (timer === "timer1") {
      setTimer1((prev) => {
        if (prev.timeLeft === 180 && !prev.isRunning) return prev;
        return { ...prev, timeLeft: 180, isRunning: false };
      });

      setJustReset(true); // ✅ Indique qu'on vient de réinitialiser
      setTimeout(() => setJustReset(false), 300); // 🔹 Réactive le clic extérieur après un court délai
      setTimeout(() => setExpandedTimer("timer1"), 50); // ✅ Garde la popin ouverte
    } 
    
    else if (timer === "timer2") {
      setTimer2((prev) => {
        if (prev.timeLeft === 14400 && !prev.isRunning) return prev;
        return { ...prev, timeLeft: 14400, isRunning: false };
      });

      setJustReset(true);
      setTimeout(() => setJustReset(false), 300);
      setTimeout(() => setExpandedTimer("timer2"), 50);
    }
  };

  /** ✅ Fonction pour **arrêter complètement** le minuteur (ferme la popin) */
  const hideTimer = (timer) => {
    setClosingTimer(timer); // Active l'animation de fermeture
    setTimeout(() => {
      if (timer === "timer1") {
        setTimer1({ isRunning: false, timeLeft: 180 });
      } else if (timer === "timer2") {
        setTimer2({ isRunning: false, timeLeft: 14400 });
      }
      setExpandedTimer(null); // ✅ Ferme la popin
      setClosingTimer(null);
    }, 300); // Temps de l'animation CSS
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (justReset) return; // ✅ Ignore le clic extérieur juste après un reset

      if (isMobile && expandedTimer && !event.target.closest(".mobile-timer")) {
        setExpandedTimer(null);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, [expandedTimer, isMobile, justReset]); // 🔹 Ajout de `justReset` comme dépendance

  return (
    <header>
      <div className="header">
          <span className="opacity"></span>
          <img src={logo} alt="Logo du site" />
          <h1 className="header-title">{title}</h1>
        </div>
     
      <button className="profile-btn" disabled>
        <FaUserCircle className="profile-icon" />
      </button>

      {/* ✅ Nouveau conteneur qui regroupe la nav et la bannière */}
      <div className="nav-banner-container">
        <nav>
          <ul className="nav">
            <li className={location.pathname === "/chatbot" ? "active" : ""}>
              <Link to="/chatbot" className="nav__link" onClick={onMenuClick}>
                <BsChatLeftDotsFill className="nav__icon" />
                <span>Dialogue IA</span>
              </Link>
            </li>
            <li className={location.pathname === "/regles" ? "active" : ""}>
              <Link to="/regles" className="nav__link" onClick={onMenuClick}>
                <GoBook className="nav__icon" />
                <span>Règle de golf</span>
              </Link>
            </li>
            <li className={location.pathname === "/temps-de-recherche" ? "active" : ""}>
              <Link to="/temps-de-recherche" className="nav__link" onClick={onMenuClick}>
                <IoTimerOutline className="nav__icon" />
                <span>Temps</span>
              </Link>
            </li>
          </ul>
        </nav>

        {/* ✅ Bannière juste sous la navigation */}
        <div className="fixed-banner">
          <img src={footerImg} alt="Bannière du site" className="footer-image" />
        </div>
      </div>

      {/* ✅ Bloque les popins si l'utilisateur est sur /temps-de-recherche */}
      {isMobile && location.pathname !== "/temps-de-recherche" && (
        <div className="mobile-timer-container">
          {expandedTimer === "timer1" || timer1.isRunning || timer1.timeLeft < 180 ? (
            <div className={`mobile-timer ${expandedTimer === "timer1" ? "expanded" : ""} 
            ${closingTimer === "timer1" ? "closing" : ""} 
            ${isDarkMode ? "dark-mode" : ""}`} 
            onClick={(e) => {
              e.stopPropagation();
              toggleTimer("timer1");
            }}
          >
              <span className="timer-text">⏳ Recherche : {formatTime(timer1.timeLeft)}</span>
              {expandedTimer === "timer1" && (
                <div className="timer-buttons">
                  <div className="btn-row">
                    <button onClick={() => setTimer1({ ...timer1, isRunning: !timer1.isRunning })}>
                      {timer1.isRunning ? "⏸ Pause" : "▶️ Reprendre"}
                    </button>
                    <button onClick={() => resetTimer("timer1")}>
                      🔁 Réinitialiser
                    </button>
                  </div>
                  <button className="btn-stop" onClick={() => hideTimer("timer1")}>
                    ⏹ Arrêter
                  </button>
                </div>
              )}
            </div>
          ) : null}

          {expandedTimer === "timer2" || timer2.isRunning || timer2.timeLeft < 14400 ? (
            <div className={`mobile-timer ${expandedTimer === "timer2" ? "expanded" : ""} 
            ${closingTimer === "timer2" ? "closing" : ""} 
            ${isDarkMode ? "dark-mode" : ""}`} 
            onClick={(e) => {
              e.stopPropagation();
              toggleTimer("timer2");
            }}
          >
              <span className="timer-text">⏳ Partie : {formatTime(timer2.timeLeft, true)}</span>
              {expandedTimer === "timer2" && (
                <div className="timer-buttons">
                  <div className="btn-row">
                    <button onClick={() => setTimer2({ ...timer2, isRunning: !timer2.isRunning })}>
                      {timer2.isRunning ? "⏸ Pause" : "▶️ Reprendre"}
                    </button>
                    <button onClick={() => resetTimer("timer2")}>
                      🔁 Réinitialiser
                    </button>
                  </div>
                  <button className="btn-stop" onClick={() => hideTimer("timer2")}>
                    ⏹ Arrêter
                  </button>
                </div>
              )}
            </div>
          ) : null}
        </div>
      )}
    </header>
  );
}