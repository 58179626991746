import React from "react";

export default function Timer({
  label,
  maxTime,
  color,
  timerData,    // Objet { isRunning, timeLeft }
  startTimer,   // Fonction pour démarrer
  stopTimer,    // Fonction pour arrêter
  resetTimer,   // Fonction pour réinitialiser
  showHours = false,
}) {
  const { isRunning, timeLeft } = timerData;

  // Couleur du texte en fonction du temps restant
  const getTimerColor = () => {
    if (timeLeft <= 10) return "red";
    if (timeLeft <= 30) return "orange";
    return "white";
  };

  // Calcule le pourcentage pour la barre circulaire
  const getCircleProgress = () => (timeLeft / maxTime) * 100;

  // Formate le temps en hh:mm:ss ou mm:ss
  const formatTime = () => {
    if (showHours) {
      const hours = Math.floor(timeLeft / 3600);
      const minutes = Math.floor((timeLeft % 3600) / 60);
      const seconds = timeLeft % 60;
      return [hours, minutes, seconds]
        .map((n) => String(n).padStart(2, "0"))
        .join(":");
    } else {
      const minutes = Math.floor(timeLeft / 60);
      const seconds = timeLeft % 60;
      return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
    }
  };

  return (
    <div className="container__timer">
      <h2>{label}</h2>
      <div style={{ position: "relative", width: "120px", height: "120px" }}>
        <svg
          width="120"
          height="120"
          viewBox="0 0 36 36"
          style={{ transform: "rotate(-90deg)" }}
        >
          <circle cx="18" cy="18" r="14" fill="none" stroke="#ddd" strokeWidth="1.5" />
          <circle
            cx="18"
            cy="18"
            r="14"
            fill="none"
            stroke={color}
            strokeWidth="1.5"
            strokeDasharray="88"
            strokeDashoffset={88 - (88 * getCircleProgress()) / 100}
            strokeLinecap="round"
            style={{ transition: "stroke-dashoffset 1s linear" }}
          />
        </svg>
        <div
          style={{
            position: "absolute",
            top: "50%", 
            left: "50%",
            transform: "translate(-50%, -50%)",
            fontSize: "1.2rem",
            color: getTimerColor(),
          }}
        >
          {formatTime()}
        </div>
      </div>

      {/* Boutons de contrôle */}
      <div style={{ marginTop: "20px" }}>
        <button onClick={isRunning ? stopTimer : startTimer}>
          {isRunning ? "⏸ Pause" : "▶️ Démarrer"}
        </button>
        <button onClick={resetTimer} style={{ marginLeft: "10px" }}>
          🔁 Réinitialiser
        </button>
      </div>
    </div>
  );
}
