import React, { useState, useEffect } from "react";

function Message({ msg }) {
  const [displayedText, setDisplayedText] = useState("");
  const isUser = msg.sender === "user";

  // Fonction qui renvoie un délai aléatoire entre deux bornes
  const getRandomDelay = (min = 20, max = 100) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  useEffect(() => {
    // Si pas de texte, ne rien faire
    if (!msg.text) {
      return;
    }
    
    // Affichage immédiat pour l'utilisateur
    if (isUser) {
      setDisplayedText(msg.text);
      return;
    }

    // Affichage progressif pour le bot
    let index = 0;
    let timeoutId;

    const typeNextLetter = () => {
      setDisplayedText((prev) => prev + msg.text.charAt(index));
      index++;

      if (index < msg.text.length) {
        // Intervalle aléatoire avant d'ajouter le caractère suivant
        const delay = getRandomDelay(10, 40);
        timeoutId = setTimeout(typeNextLetter, delay);
      }
    };

    typeNextLetter();

    // Nettoyage
    return () => clearTimeout(timeoutId);
  }, [msg]);

  return (
    <div className={`message ${isUser ? "user-message" : "bot-message"}`}>
      {/* Container spécifique si message avec image */}
      <div className={`message-content ${msg.image ? "with-image" : ""}`}>
        {/* Affichage de l'image si présente */}
        {msg.image && (
          <div className="message-image-wrapper">
            <img 
              src={msg.image} 
              alt="Image partagée" 
              className="message-image"
            />
          </div>
        )}
        
        {/* Affichage du texte */}
        {msg.text && <div className="message-text">{displayedText}</div>}
      </div>
      
      {/* Styles pour les messages avec images */}
      <style jsx>{`
        .message-content.with-image {
          display: flex;
          flex-direction: column;
          max-width: 280px;
        }
        
        .message-image-wrapper {
          margin-bottom: ${msg.text ? '8px' : '0'};
          overflow: hidden;
          border-radius: 10px;
          position: relative;
        }
        
        .message-image {
          width: 100%;
          max-height: 250px;
          display: block;
          object-fit: contain;
          transition: transform 0.3s ease;
        }
        
        .message-image-wrapper:hover .message-image {
          transform: scale(1.02);
        }
        
        /* Adaptation responsive */
        @media screen and (max-width: 768px) {
          .message-content.with-image {
            max-width: 240px;
          }
          
          .message-image {
            max-height: 200px;
          }
        }
        
        @media screen and (max-width: 480px) {
          .message-content.with-image {
            max-width: 200px;
          }
          
          .message-image {
            max-height: 180px;
          }
        }
      `}</style>
    </div>
  );
}

export default Message;