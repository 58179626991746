import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Header from "./composents/Header";
import Chatbot from "./composents/chatbot";
import Recherche from "./composents/recherche";
import Rules from "./composents/regles";
import Footer from "./composents/Footer";

import "./styles/main.scss";

function App() {
  const location = useLocation();
  const [animateKey, setAnimateKey] = useState(0);

  // États des minuteurs
  const [timer1, setTimer1] = useState({
    isRunning: false,
    timeLeft: 180, // 3 minutes
  });

  const [timer2, setTimer2] = useState({
    isRunning: false,
    timeLeft: 14400, // 4 heures
  });

  // Gestion automatique des minuteurs
  useEffect(() => {
    let interval1, interval2;

    if (timer1.isRunning && timer1.timeLeft > 0) {
      interval1 = setInterval(() => {
        setTimer1((prev) => ({
          ...prev,
          timeLeft: Math.max(prev.timeLeft - 1, 0),
        }));
      }, 1000);
    }

    if (timer2.isRunning && timer2.timeLeft > 0) {
      interval2 = setInterval(() => {
        setTimer2((prev) => ({
          ...prev,
          timeLeft: Math.max(prev.timeLeft - 1, 0),
        }));
      }, 1000);
    }

    return () => {
      clearInterval(interval1);
      clearInterval(interval2);
    };
  }, [timer1.isRunning, timer1.timeLeft, timer2.isRunning, timer2.timeLeft]);

  // Forcer le remount du composant à chaque changement de route
  useEffect(() => {
    setAnimateKey((prevKey) => prevKey + 1);
  }, [location.pathname]);

  // Titre dynamique en fonction de l'URL
  const getTitle = () => {
    switch (location.pathname) {
      case "/chatbot":
        return "AI golf rules";
      case "/regles":
        return "Règle de Golf";
      case "/temps-de-recherche":
        return "Temps";
      default:
        return "GOLF RULES IA";
    }
  };

  

  return (
    <div className="main-container">
      <Header
        title={getTitle()}
        timer1={timer1}
        timer2={timer2}
        setTimer1={setTimer1}
        setTimer2={setTimer2}
      />

      {/* Utilisation de animateKey pour forcer le remount sur Routes */}
      <Routes key={animateKey}>
        <Route path="/" element={<Chatbot />} />
        <Route path="/chatbot" element={<Chatbot />} />
        <Route path="/regles" element={<Rules />} />
        <Route
          path="/temps-de-recherche"
          element={
            <Recherche
              timer1={timer1}
              setTimer1={setTimer1}
              timer2={timer2}
              setTimer2={setTimer2}
            />
          }
        />
      </Routes>


      <Footer/>
    </div>
  );
}

export default App;
