// Recherche.jsx
import React from "react";
import Timer from "./timer"; // Assurez-vous que le nom du fichier correspond (Timer.jsx)
import { MdHeight } from "react-icons/md";

export default function Recherche({ timer1, setTimer1, timer2, setTimer2 }) {
  // Contrôles pour le premier minuteur (3 minutes)
  const startTimer1 = () => setTimer1((prev) => ({ ...prev, isRunning: true }));
  const stopTimer1 = () => setTimer1((prev) => ({ ...prev, isRunning: false }));
  const resetTimer1 = () => setTimer1({ isRunning: false, timeLeft: 180 });

  // Contrôles pour le deuxième minuteur (4 heures)
  const startTimer2 = () => setTimer2((prev) => ({ ...prev, isRunning: true }));
  const stopTimer2 = () => setTimer2((prev) => ({ ...prev, isRunning: false }));
  const resetTimer2 = () => setTimer2({ isRunning: false, timeLeft: 14400 });

  return (
    <div className="main-component" style={{height: '975px'}}>
      <div
        className="container__timer__main"
        style={{ display: "flex", gap: "2rem" }}
      >
        {/* Minuteur pour la recherche (3 minutes) */}
        <Timer
          label="Temps de Recherche"
          maxTime={180}
          color="green"
          timerData={timer1}
          startTimer={startTimer1}
          stopTimer={stopTimer1}
          resetTimer={resetTimer1}
          showHours={false}
        />
        {/* Minuteur pour la partie (4 heures) */}
        <Timer
          label="Temps de Partie"
          maxTime={14400}
          color="green"
          timerData={timer2}
          startTimer={startTimer2}
          stopTimer={stopTimer2}
          resetTimer={resetTimer2}
          showHours={true}
        />
      </div>

      <div className="container__rules" style={{ marginTop: "30px" }}>
        <p className="container__rules__name">
          Règle 18.2a - Temps autorisé pour la recherche
        </p>
        <p>
        Une balle est perdue si elle n'est pas retrouvée en trois minutes après le début de la recherche.</p>
        <br />
        <p>
        Si la recherche est interrompue pour une raison valable (jeu interrompu, autre joueur jouant, erreur d'identification), le temps d'arrêt n'est pas compté. La durée totale de recherche reste trois minutes, en cumulant les périodes avant et après l'interruption.        </p>
      </div>
    </div>
  );
}
