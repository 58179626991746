import React from "react";
import footer from "../img/banniereNAO&CO.jpg";

function Footer() {
  return (
    <footer className="footer">
      
    </footer>
  );
}

export default Footer;
